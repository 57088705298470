import slug                from 'slug';
import store               from '@/store';
import {isCluji, isBureau} from '@/util/user';

export const linkToDetail = (evenement, route) => ({
    // If we are on a dashboard, stay in the dashboard, if we are in the public page, stay there too
    name: route.matched[0].name === 'classic_pages' ? 'evenement' : 'dashboard_evenement',
    params: {
        eventId: evenement.id,
        eventName: slug(evenement.nom)
    }
});

export const canEdit = (evenement, user = store.state.user.utilisateur) =>
    isCluji(user) && (isBureau(user) || evenement.organisateur.id === user.id);

export default {linkToDetail, canEdit};