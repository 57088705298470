<template>
    <card-table-search :items="evenements" :fields="fields" :actions="actions"
                       :custom-rendered="['nom', 'valide', 'organisateur']"
                       title="Liste des événements">
        <template #nom="{row}">
            <router-link :to="linkToDetail(row.item, $route)">
                {{ row.item.nom }}
            </router-link>
        </template>
        <template #valide="{row}">
            <b-badge :variant="getBadgeColorStatut(row.item)">
                {{ getTextStatut(row.item) }}
            </b-badge>
        </template>
        <template #organisateur="{row}">
            <user-name :user="row.item.organisateur"/>
        </template>
    </card-table-search>
</template>

<script>
    const DisplayInfosEvenementModal = () => import('@/components/modals/evenement/DisplayInfosEvenementModal');
    const CardTableSearch            = () => import('@/components/CardTableSearch');
    const UserName                   = () => import('@/components/UserName');
    const CommentValidationModal     = () => import('@/components/modals/CommentValidationModal');

    import demande              from '@/util/demande';
    import alert                from '@/util/alert';
    import {apiPath}            from '@/util/http';
    import {linkToDetail}       from '@/util/evenement';
    import {updateBadgesBureau} from '@/util/badge';

    export default {
        name: 'AdminEvenements',
        components: {CardTableSearch, UserName},
        data() {
            return {
                evenements: [],
                fields: [
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'organisateur',
                        label: 'Organisateur',
                        sortable: true
                    },
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => demande.formatDates(item)
                    },
                    {
                        key: 'resume',
                        label: 'Résumé',
                        sortable: true
                    },
                    {
                        key: 'valide',
                        label: 'Statut',
                        sortable: true,
                        formatter: (value, key, item) => demande.getTextStatut(item)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_accept',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Accepter',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmEvenement(item, true)
                    },
                    {
                        key: 'action_refuse',
                        color: () => 'danger',
                        icon: 'times',
                        tooltip: 'Refuser',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmEvenement(item, false)
                    },
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Afficher',
                        handler: ({item}) => this.$store.dispatch('modal/create', {
                            component: DisplayInfosEvenementModal,
                            props: {evenement: item}
                        })
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.deleteEvenement(item)
                    }
                ]
            };
        },
        methods: {
            linkToDetail,
            ...demande,
            confirmEvenement(emprunt, valid) {
                this.$store.dispatch('modal/create', {
                    component: CommentValidationModal,
                    props: {
                        title: (valid ? 'Validation' : 'Refus') + ' de l\'événement',
                        callback: (commentaire, notifyDiscord) => this.axios
                            .post(
                                apiPath('confirm_evenement_admin', {evenement: emprunt.id}),
                                {valid: valid, commentaire: commentaire, notify_discord: notifyDiscord}
                            )
                            .then(() => {
                                this.$toaster.success('Événement ' + (valid ? 'validé' : 'refusé') + ' avec succès');
                                this.loadData();
                                updateBadgesBureau();
                            })
                            .catch(() => this.$toaster.error('Impossible de modifier l\'événement'))
                    }
                });
            },
            deleteEvenement(evenement) {
                alert.confirm(
                    'Supprimer l\'événement "' + evenement.nom + '" ?',
                    () => this.axios.delete(apiPath('delete_evenement', {evenement: evenement.id}))
                        .then(() => {
                            this.$toaster.success('Événement supprimé');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer l\'événement'))
                );
            },
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_evenements_admin'))
                    .then(response => this.evenements = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des événements'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>
